$optum-blue: #4177CB;
$optum-navyblue: #0B2772;
$optum-white: #FFFFFF;
$optum-gray: #595959;
$optum-orange: #EE6C3E;
$optum-lightblue: #DFF5F9;
$optum-beige: #FBF9F4;
$optum-lightgray: #E0E0E0;

.hero-div{
  height: 565px;
}

.hero-bg-blue {
  background: $optum-navyblue;
}

.font-white {
  color: $optum-white !important;
}

.hero-bg {
  background-position: center;
  background-size: cover;
}

.hero-wrapper {
  position: relative;
}

.hero-wrapper::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  filter: grayscale(100%);
}

.hero-wrapper::after {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: linear-gradient(270deg,rgba(23,23,23,0),#313131);
  color: #fff;
}
.hero-inside {
  /* This will make it stack on top of the ::before */
  position: relative;
}

$optum-blue: #0C55B8;
$optum-navyblue: #002677;
$optum-white: #FFFFFF;
$optum-gray: #595959;
$optum-orange: #FF612B;
$optum-lightblue: #D9F6FA;
$optum-beige: #FBF9F4;
$optum-lightgray: #E0E0E0;

$optum-n80: #323334;

$primary-color-1: $optum-navyblue;
$primary-color-2: $optum-orange;

.optum-btn {
  border-radius: 10px !important;
  background: $optum-beige !important;
  height: 80px !important;
  width: 90% !important;
  border-color: $optum-beige !important;
}

.optum-btn:hover {
  background: $optum-lightblue !important;
  border-color: $optum-lightblue !important;
}

.btn-light {
  color: white !important;
  border-radius: 20px !important;
  background: $optum-navyblue !important;
  padding: 12px 40px !important;
  // width: 90% !important;
  height: 50px !important;
  border-color: $optum-lightblue !important;
}

.btn-light:hover {
  background: #0C55B8 !important;
  height: 50px !important;
  border-color: white !important;
}

.btn-white {
  border-radius: 20px !important;
  background: $optum-white !important;
  padding: 13px 60px !important;
  height: 50px !important;
  color: $optum-navyblue;
  border-color: $optum-navyblue !important;
}

.btn-white:hover {
  background: $optum-lightblue !important;
  height: 50px !important;
  border-color: $optum-lightblue !important;
}
.footer-aux-container {
  width: 90% !important;
  max-width: 100%;
  @media only screen and (max-width: 550px) {
    width: 82%;
    margin: auto;
    padding: 0px 5px;

    .mobile-first {
      margin-bottom: 10px;
    }

    .mobile-last {
      margin-top: 10px;
      margin-bottom: -20px;
    }

  }

}

.footer-aux {
  border-top: 1px solid #000;
  padding-top: 45px;

  h4 {
    text-transform: uppercase;
    font-size: 14px;
    color: #000;
  }

  ul {
    list-style-type: none !important;
    padding-inline-start: 0px;
    margin-top: -10px;
  }

  a {
    color: #000 !important;
    font-size: 12px;
  }
}

.locations-detail-card-container {
  border: #B7B7B7 1px solid;
  width: 100%;
  padding: 20px 20px 10px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 0 rgb(0,0,0, .15);

  .green-text {
    font-weight: bold;
    color: #478847;
  }

  .gray-fa {
    font-color: #595959;
  }

  .location-bottom-section {
    display: flex;
  }

  #locations-detail-card-section-3 {
    padding: 0px;
    margin-top: -28px;
    .nav-link {
      color: #316BBE !important;
    }
  }

  .dropleft .dropdown-toggle::before {
    transform: rotate(270deg);
  }
}

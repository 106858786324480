.top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }

  .icon-style{
    background-color: black;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    color: #fff;
    cursor: pointer;
    transition: all .5s ease-in-out;
  }

  .icon-style:hover{
    animation: none;
    background: #fff;
    color: #0B2772;
    border: 2px solid #0B2772;
  }

  .icon-format{
    font-size: 50px;
    position: absolute;
    top: 8px;
    left: 18px;
  }
  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }
.provider-single {
  .row {
    margin: auto;
    width: 100%;
  }

  .provider-single-breadcrumb {
    position: absolute;
    top:15px;
    left:3%;
  }

  .provider-single .right-section-info .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .organizations-heading {
    margin-top: 60px;
  }
  #provider-map {
    height: 300px;
  }


  @media only screen and (max-width: 767.98px) {
   h1{
     font-size: 35px!important;
   }
    h2{
      font-size: 30px!important;
    }
    h3{
      font-size: 30px!important;
    }
  }


}

.right-section-info {
  padding-left: 0px;
  padding-right: 0px;

  img {
    width: 100%;
    height: auto;
  }

  .googleMap{
    border: 0!important;
  }
}

.big-icon{
  font-size: 20px;
}


$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-chevron-right' width='14' height='14'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z' fill='currentColor'/%3E%3C/svg%3E");
$optum-breadcrumb-divider: url('../../img/chevron-right.svg');
$optum-blue: #0C55B8;
$optum-navyblue: #002677;
$optum-white: #FFFFFF;
$optum-gray: #595959;
$optum-orange: #FF612B;
$optum-lightblue: #D9F6FA;
$optum-beige: #FBF9F4;
$optum-lightgray: #F2F2F2;
$optum-n80: #323334;

.breadcrumb {
  background-color: white !important;
}

.breadcrumb-item+.breadcrumb-item::before{
  content:var(--bs-breadcrumb-divider, $optum-breadcrumb-divider) !important;
  color: $optum-blue !important;
}

.breadcrumb-item::before {
  color: $optum-blue !important;
}
.faq-item {
  padding: 25px 15px;
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
}

.font-blue {
  color: #4177CB !important;
  size: 24px !important;
}

.accordion-button h6 {
  width: 100% !important;
}

.accordion-button {
  width: 100% !important;
}